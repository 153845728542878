<template>
    <div>
        <h2 class="az-content-title tx-24 mg-b-5">Asset Registry</h2>
        <p class="mg-b-25">Management of items of property owned by a person or company, regarded as having value, commitments, or legacies.</p>
    </div>
</template>

<script>
    export default {
        name: "Home"
    }
</script>

<style scoped>

</style>